import cn from 'classnames';
import { capitalize } from 'lodash';
import _ from 'lodash/fp';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import orderBy from 'lodash/fp/orderBy';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import useEventListener from '../../hooks/useEventListener';
import { getAdminJobsList, getJobsList } from '../../redux/actions';
import { profileSelectors } from '../../redux/selectors/profile';
import text from '../../text';
import { isStaff } from '../../utilities/user';
import Loading from '../loading/Loading';
import { UNTITLED, statuses } from './consts';
import StatusFilterIcon from './filters/StatusFilterIcon';
import InviteRow from './inviteRow';
import OrderListingItem, { COLUMN } from './orderListingItem';

const NUM_SHOW_INITIAL = 20;
const NUM_SHOW_MORE = 10;

const isAscendingOrder = (order) => order && order === 'asc';

const Row = ({
  data: { item, currentUser, isAdminOrigin, isStaff },
  index,
  style,
}) => {
  // const item = useMemo(() => listItems[index], [listItems]);
  return item.type === 'invite' ? (
    <InviteRow key={index} job={item.job} style={style} />
  ) : (
    <OrderListingItem
      key={index}
      job={item.job}
      currentUser={currentUser}
      isAdminOrigin={isAdminOrigin}
      isStaff={isStaff}
      style={style}
    />
  );
};

export const ProjectListing = ({
  jobsList,
  currentUser,
  statusCounts,
  isAdminOrigin,
  isStaff,
  isLoading,
  status,
  searchText = '',
}) => {
  const [sortingProperty, setSortingProperty] = useState('updated_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [orderedJobsList, setOrderedJobsList] = useState(jobsList);
  const filterStatus = status.status;
  const [showUpTo, setShowUpTo] = useState(NUM_SHOW_INITIAL);
  const orderListingRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (jobsList) {
      const sortedList = orderBy(
        sortingProperty === 'status'
          ? [(job) => statuses[job.status].order]
          : [get(sortingProperty)],
        [sortOrder],
        jobsList
      );

      const filteredList = filter(({ status, project, full_address }) => {
        const filterByStatus = (status) => {
          return filterStatus.includes(status);
        };

        const filterBySearchText = (projectName, fullAddress) => {
          const searchByText = (property) => {
            return property.toLowerCase().includes(searchText.toLowerCase());
          };

          if (searchText === '') return true;
          if (projectName)
            return searchByText(projectName) || searchByText(fullAddress);
          if (projectName === null)
            return searchByText(UNTITLED) || searchByText(fullAddress);
          return false;
        };

        return (
          filterByStatus(status) &&
          filterBySearchText(project?.name, full_address)
        );
      })(sortedList);

      setOrderedJobsList(filteredList);
    }
  }, [sortingProperty, sortOrder, jobsList, status]);

  const handleSetSortingProperty = (property) => {
    setSortingProperty(property);
    setSortOrder(isAscendingOrder(sortOrder) ? 'desc' : 'asc');
  };

  const renderSortableHeading = (header, propertyId, className) => {
    return (
      <th
        className={cn('column-header', {
          [className]: className,
        })}
        onClick={() => handleSetSortingProperty(propertyId)}
      >
        {propertyId === 'status' ? (
          <>
            <StatusFilterIcon status={filterStatus} />
            <span className='mx-4'>{header}</span>
          </>
        ) : (
          header
        )}

        <a className='up-icon'>
          {sortingProperty === propertyId ? (
            <img
              src={
                !isAscendingOrder(sortOrder)
                  ? '/public/img/icon-up.svg'
                  : '/public/img/icon-down.svg'
              }
            />
          ) : (
            <div />
          )}
        </a>
      </th>
    );
  };

  const noOrdersAvailable = [...filterStatus].map((status) => {
    if (status === 'pending') return 'Quoted';
    if (status === 'inprogress') return 'Commenced';
    return capitalize(status);
  });

  const totalNumListItems = orderedJobsList?.length;
  const { listItems, numListItems } = useMemo(
    () =>
      orderedJobsList
        ? _.reduce(
            ({ listItems, numListItems }, job) => {
              if (numListItems > showUpTo) {
                return { listItems, numListItems };
              }
              listItems.push({ type: 'job', job });
              if (job.user_project?.invite_status === 'pending') {
                listItems.push({ type: 'invite', job });
              }
              numListItems++;
              return { listItems, numListItems };
            },
            { listItems: [], numListItems: 0 },
            orderedJobsList
          )
        : [],
    [orderedJobsList, showUpTo]
  );

  const showMore = () => {
    setShowUpTo((prev) => prev + NUM_SHOW_MORE);
  };

  const getOrderListingContainerHeight = useCallback(() => {
    if (orderListingRef.current && listItems.length > 0) {
      setTimeout(() => setHeight(orderListingRef.current?.clientHeight), 300);
    }
  }, [orderListingRef, listItems]);

  useEventListener('resize', getOrderListingContainerHeight);

  useEffect(() => {
    getOrderListingContainerHeight();
  }, [getOrderListingContainerHeight]);

  if (orderedJobsList === undefined) {
    return null;
  }

  return !isLoading ? (
    <div
      id='OrderListing'
      className='overflow-hidden position-relative d-flex flex-column flex-fill'
      ref={orderListingRef}
    >
      {listItems.length === 0 && (
        <div className='p-4 no-order-container'>
          <p className='text-center mb-0'>
            {text('noOrdersAvailable', {
              status: (
                <span className='font-weight-bold'>
                  {noOrdersAvailable.join(', ')}.
                </span>
              ),
            })}
          </p>
        </div>
      )}

      {listItems.length > 0 && (
        <InfiniteScroll
          dataLength={listItems.length}
          next={showMore}
          hasMore={numListItems < totalNumListItems}
          loader={<Loading />}
          scrollableTarget='order-table'
        >
          <div
            className='dashboard-table table-responsive'
            id='order-table'
            style={{
              ...(height && {
                maxHeight: `${height}px`,
              }),
            }}
          >
            <table className='table'>
              <thead>
                <tr>
                  {renderSortableHeading(
                    text('projectName'),
                    'project.name',
                    COLUMN.NAME
                  )}
                  {renderSortableHeading(
                    text('siteAddress'),
                    'full_address',
                    COLUMN.ADDRESS
                  )}
                  <th className={COLUMN.ORDER_COUNT} />
                  {renderSortableHeading(
                    text('createdDate'),
                    'created_at',
                    COLUMN.DATE
                  )}
                  {renderSortableHeading(
                    text('updatedDate'),
                    'updated_at',
                    COLUMN.DATE
                  )}
                  {renderSortableHeading(
                    text('status'),
                    'status',
                    COLUMN.STATUS
                  )}
                  <th className={COLUMN.ACTIONS}>{text('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {_.map(
                  (item) => (
                    <Row
                      key={item.job.id}
                      data={{
                        item,
                        currentUser,
                        isAdminOrigin,
                        isStaff,
                      }}
                    />
                  ),
                  listItems
                )}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
      )}
    </div>
  ) : (
    <Loading />
  );
};

export const useProjectListing = () => {
  const isAdmin = useSelector(profileSelectors.getIsAdmin);
  const { currentUser, status, searchText, ...state } = useSelector(
    (state) => ({
      jobsList: state.jobsReducer.jobsList,
      statusCounts: state.jobsReducer.statusCounts,
      isJobsListLoading: state.jobsReducer.isJobsListLoading,

      adminJobList: state.jobsReducer.adminJobList,
      adminStatusCounts: state.jobsReducer.adminStatusCounts,
      isAdminJobListLoading: state.jobsReducer.isAdminJobListLoading,

      currentUser: state.profileReducer.userProfile,
      status: state.jobsReducer.listJobFilter,
      searchText: state.jobsReducer.listJobFilter.searchText,
    })
  );
  const listingParams = useMemo(
    () =>
      isAdmin
        ? {
            isLoading: state.isAdminJobListLoading,
            jobsList: state.adminJobList,
            statusCounts: state.adminStatusCounts,
          }
        : {
            isLoading: state.isJobsListLoading,
            jobsList: state.jobsList,
            statusCounts: state.statusCounts,
          },
    [isAdmin, state]
  );
  return { isAdmin, currentUser, status, searchText, ...listingParams };
};

const ProjectListingWithRedux = () => {
  const dispatch = useDispatch();

  const {
    isAdmin,
    currentUser,
    status,
    searchText,
    isLoading,
    jobsList,
    statusCounts,
  } = useProjectListing();

  // todo: only fetch once
  useEffect(() => {
    if (isAdmin) {
      dispatch(getAdminJobsList());
    } else {
      dispatch(getJobsList());
    }
  }, [isAdmin]);

  return (
    <ProjectListing
      currentUser={currentUser}
      isLoading={isLoading}
      isStaff={isStaff(currentUser?.role)}
      jobsList={jobsList}
      statusCounts={statusCounts}
      status={status}
      searchText={searchText}
    />
  );
};

export default ProjectListingWithRedux;
