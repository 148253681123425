/* eslint-disable react/prop-types */
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Dropdown, Overlay, Tooltip } from 'react-bootstrap';

import useBreakpoint from '../../hooks/useBreakpoint';
import { useClickOutside } from '../../hooks/useClickOutside';
import Icon from '../icon/Icon';

const MeatballDropdown = (
  {
    children,
    className,
    disabled,
    btnClassName,
    toggleText,
    tooltipProps,
    onOpen,
    align = 'right',
    variant = 'outline-primary',
  },
  ref
) => {
  const target = useRef();
  const isEnabledForBreakpoint = useBreakpoint(['md', 'lg', 'xl']);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const enableTooltip = !isEmpty(tooltipProps) && isEnabledForBreakpoint;
  const handleToggle = (nextIsOpen, event) => {
    if (
      event.currentTarget.classList &&
      event.currentTarget.classList.contains('dropdown-item') &&
      event.currentTarget.classList.contains('SubmenuButton')
    ) {
      return;
    }
    setIsOpen(nextIsOpen);
  };

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  useClickOutside(
    ref,
    (event) => {
      if (event.button === 2) {
        // right-click
        setIsOpen(false);
      }
    },
    [],
    !isOpen
  );

  return (
    <>
      <Dropdown
        show={isOpen}
        bsPrefix='MeatballDropdown'
        onToggle={handleToggle}
        ref={ref}
        className={className}
      >
        {/* Container for the overlay to pass ref */}
        <div
          ref={target}
          onBlur={() => enableTooltip && setIsTooltipVisible(false)}
          onMouseEnter={() => enableTooltip && setIsTooltipVisible(true)}
          onMouseLeave={() => enableTooltip && setIsTooltipVisible(false)}
          className='ToggleContainer'
          disabled={disabled}
        >
          <Dropdown.Toggle
            disabled={disabled}
            variant={variant}
            bsPrefix={classnames('Toggle', {
              [btnClassName]: btnClassName,
              simple: !btnClassName,
            })}
          >
            <Icon icon='more' />
            {toggleText && <p className='m-0 ml-2'>{toggleText}</p>}
          </Dropdown.Toggle>
        </div>

        <Dropdown.Menu align={align}>{children}</Dropdown.Menu>
      </Dropdown>
      {enableTooltip && (
        <Overlay
          placement={tooltipProps.placement ?? 'bottom'}
          target={target.current}
          show={isTooltipVisible}
        >
          {(props) => (
            <Tooltip id='meatballdropdown-tooltip' {...props}>
              {tooltipProps.text}
            </Tooltip>
          )}
        </Overlay>
      )}
    </>
  );
};

export default forwardRef(MeatballDropdown);
