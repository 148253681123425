import { FILE_FORMAT } from '../constants/asset';

export const generateDefaultActivity = () => ({
  type: 'note',
  title: 'Note from LARKI',
  description: `No activities found.\n
  Please check back again soon to see project updates.`,
  detailed_status_update: null,
  metadata: {},
  visibility: 'default',
  is_notification_sent: false,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  deleted_at: null,
  created_user_id: null,
  job_id: null,
  created_user: {},
  created_assets: [],
  superseded_assets: [],
  removed_assets: [],
});

export const isActivityType = (activity, type) =>
  activity && activity.type && activity.type === type;

export const isActivityFileDownload = (activity) => {
  const [, format] =
    activity.description.match(/\.([0-9a-z]+)(?:[\?#]|$)/i) || []; // get file extension
  const flattenedFileFormats = Object.values(FILE_FORMAT).reduce(
    (acc, next) => [...acc, ...next],
    []
  );
  return (
    activity.title.includes('File downloaded') &&
    flattenedFileFormats.includes(format)
  );
};
