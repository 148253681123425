import isEmpty from 'lodash/isEmpty';
import React from 'react';
import List from '../../../components/list/List';
import text, { formatSqmArea, formatCurrency } from '../../../text';

const getDescription = (displayName, details) =>
  details?.area_in_sqm
    ? text('productData', {
        product: displayName,
        area: formatSqmArea(details.area_in_sqm.toFixed()),
      })
    : displayName;

const ChargeItem = (props) => {
  const {
    displayName,
    details,
    price,
    quote,
    discounts,
    fees,
  } = props;

  return (
    <tbody className='ChargeItem'>
      <List.Item
        className={'charge'}
        description={getDescription(displayName, details)}
        value={
          price.base
            ? formatCurrency(
                price.base,
                quote.currency.name,
                quote.currency.scale_factor,
                {},
                true
              )
            : text('toBeConfirmed')
        }
      />
      {!isEmpty(fees) &&
        fees.map((fee, i) => (
          <List.Item
            key={i}
            description={fee.display_name}
            value={`${formatCurrency(
              fee.price.total,
              quote.currency.name,
              quote.currency.scale_factor,
              {},
              true
            )}`}
          />
        ))}

      {!isEmpty(discounts) &&
        discounts.map((discount, i) => (
          <List.Item
            key={i}
            description={discount.display_name}
            value={`—${formatCurrency(
              discount.price.discount,
              quote.currency.name,
              quote.currency.scale_factor,
              {},
              true
            )}`}
            className={'discount font-pink pl-3 font-italic'}
          />
        ))}
    </tbody>
  );
};

export default ChargeItem;
