import * as turf from '@turf/turf';
import { computeDestinationPoint, getDistance } from 'geolib';
import _ from 'lodash';
import geohash from 'ngeohash';
import polylabel from 'polylabel';
// we've had issues with turf.js in computing intersection: https://github.com/mfogel/polygon-clipping/issues/91 https://github.com/Turfjs/turf/issues/2277
// so using polyclip-ts for intersection, union, difference
import * as polyclip from 'polyclip-ts';
import proj4 from 'proj4';

import assert from '../../utilities/assert';

function computeSurroundingPolygonFromPolyline(polyline) {
  const LINE_WIDTH_IN_METERS = 20;

  const path = polyline.getPath();

  let surroundingPolygonComponents = [];
  for (let i = 0; i < path.length - 1; i++) {
    const p1 = turf.point([path.getAt(i).lng(), path.getAt(i).lat()]);
    const p2 = turf.point([path.getAt(i + 1).lng(), path.getAt(i + 1).lat()]);

    const bearing = turf.bearing(p1, p2);
    const p0a = turf.destination(p1, LINE_WIDTH_IN_METERS, bearing + 90, {
      units: 'meters',
    });
    const p0b = turf.destination(p1, LINE_WIDTH_IN_METERS, bearing - 90, {
      units: 'meters',
    });
    const p1a = turf.destination(p2, LINE_WIDTH_IN_METERS, bearing + 90, {
      units: 'meters',
    });
    const p1b = turf.destination(p2, LINE_WIDTH_IN_METERS, bearing - 90, {
      units: 'meters',
    });

    const surroundingRectangle = turf.polygon([
      [
        turf.getCoord(p0a),
        turf.getCoord(p0b),
        turf.getCoord(p1b),
        turf.getCoord(p1a),
        turf.getCoord(p0a),
      ],
    ]);
    surroundingPolygonComponents.push(surroundingRectangle);

    if (i > 0 && i < path.length - 1) {
      const p0 = turf.point([path.getAt(i - 1).lng(), path.getAt(i - 1).lat()]);
      const priorBearing = turf.bearing(p0, p1);

      const a0 = priorBearing - bearing < 0 ? priorBearing - 90 : bearing + 90;
      const a1 = priorBearing - bearing < 0 ? bearing - 90 : priorBearing + 90;

      const controlPointSector = turf.sector(p1, LINE_WIDTH_IN_METERS, a0, a1, {
        units: 'meters',
      });
      surroundingPolygonComponents.push(controlPointSector);
    }
  }

  let surroundingPolygon = turf.polygon([]);
  for (let i = 0; i < surroundingPolygonComponents.length; i++) {
    surroundingPolygon = turf.union(
      surroundingPolygon,
      surroundingPolygonComponents[i]
    );
  }

  // apply a fudge factor to make sure segments overlap and merge with rectangles
  surroundingPolygon = turf.buffer(surroundingPolygon, 0.001, {
    units: 'meters',
  });

  return surroundingPolygon.geometry.coordinates.map((ring) =>
    ring.map((point) => new google.maps.LatLng(point[1], point[0]))
  );
}

// Hand-rolled - uses geodesic("+a=6378137")
function computeRectangleAroundLocation(location, radius) {
  const EARTH_RADIUS_IN_KILOMETERS = 6378.137;
  const DEGREES_TO_RADIANS = Math.PI / 180.0;
  const RADIANS_TO_DEGREES = 180.0 / Math.PI;

  return {
    north:
      location.lat +
      (radius.dy / EARTH_RADIUS_IN_KILOMETERS) * RADIANS_TO_DEGREES,
    south:
      location.lat +
      (-radius.dy / EARTH_RADIUS_IN_KILOMETERS) * RADIANS_TO_DEGREES,
    east:
      location.lng +
      ((radius.dx / EARTH_RADIUS_IN_KILOMETERS) * RADIANS_TO_DEGREES) /
        Math.cos(location.lat * DEGREES_TO_RADIANS),
    west:
      location.lng +
      ((-radius.dx / EARTH_RADIUS_IN_KILOMETERS) * RADIANS_TO_DEGREES) /
        Math.cos(location.lat * DEGREES_TO_RADIANS),
  };
}

function computeSquarePolygonAroundLocation(location, size = 50) {
  // In the scenario where there is no region to add the selection to
  // create a 50x50 polygon aligned with the center of the user's view.
  const moveIn = (oldPoint, bearing) => {
    const newPoint = computeDestinationPoint(oldPoint, size / 2, bearing);
    return newPoint;
  };
  location = { latitude: location.lat(), longitude: location.lng() };
  const south = moveIn(location, 180);
  const southWest = moveIn(south, 270);
  const southEast = moveIn(south, 90);
  const north = moveIn(location, 0);
  const northWest = moveIn(north, 270);
  const northEast = moveIn(north, 90);
  const newRegion = {
    type: 'Polygon',
    include: [southWest, northWest, northEast, southEast].map((c) => [
      c.latitude,
      c.longitude,
    ]),
    exclude: [],
  };
  return newRegion;
}

/**
 * Computes the **outer** perimeter of a shape defined by it's `include` and `exclude` coordinates.
 * Expects `include` to be a list of [lat, lng] coordinates.
 * Expects `exclude` to be a list of lists of [lat, lng] coordinates.
 */
function computePerimeter(include, exclude) {
  if (include.length < 1) {
    return 0;
  }
  const includeShifted = [..._.drop(include, 1), _.first(include)];
  const zipped = _.zip(include, includeShifted);
  const perimeter = _.reduce(
    zipped,
    function (sum, [[lat1, lng1], [lat2, lng2]]) {
      return (
        sum + getDistance({ lat: lat1, lon: lng1 }, { lat: lat2, lon: lng2 })
      );
    },
    0
  );
  return perimeter;
}

const geoJsonGeometryToTurfPolygonOrMultiPolygon = (geometry) => {
  if (geometry.type === 'Polygon') {
    return turf.polygon(geometry.coordinates);
  }
  if (geometry.type === 'MultiPolygon') {
    return turf.multiPolygon(geometry.coordinates);
  }
  throw new Error('Invalid feature type', geometry);
};

/**
 * Computes the union between two (multi-)polygons.
 * @param a GeoJSON geometry of a polygon or multi-polygon
 * @param b GeoJSON geometry of a polygon or multi-polygon
 * @returns GeoJSON geometry of the union of `a` and `b`
 */
function computeUnion(a, b) {
  const polyA = geoJsonGeometryToTurfPolygonOrMultiPolygon(a);
  const polyB = geoJsonGeometryToTurfPolygonOrMultiPolygon(b);
  const unionCoords = polyclip.union(
    polyA.geometry.coordinates,
    polyB.geometry.coordinates
  );
  if (unionCoords.length === 0) return null;
  return { type: 'MultiPolygon', coordinates: unionCoords };
}

/**
 * Computes the union between N (multi)-polygons.
 * @param geoms array of GeoJSON geometry of polygons of multi-polygon
 * @returns GeoJSON geometry of union of all `geoms`.
 */
function computeUnionMulti(geoms) {
  console.assert(geoms.length >= 1);
  return geoms.reduce((acc, geom) => {
    if (!acc) {
      return geom;
    }
    return computeUnion(acc, geom);
  }, null);
}

/**
 * Computes the difference between two (multi-)polygons.
 * @param a GeoJSON geometry of a polygon or multi-polygon
 * @param b GeoJSON geometry of a polygon or multi-polygon
 * @returns GeoJSON geometry of the difference of `a` and `b`
 */
function computeDifference(a, b) {
  const polyA = geoJsonGeometryToTurfPolygonOrMultiPolygon(a);
  const polyB = geoJsonGeometryToTurfPolygonOrMultiPolygon(b);
  const differenceCoords = polyclip.difference(
    polyA.geometry.coordinates,
    polyB.geometry.coordinates
  );
  if (differenceCoords.length === 0) return null;
  return { type: 'MultiPolygon', coordinates: differenceCoords };
}

/**
 * Computes the intersection between two (multi-)polygons.
 * @param a GeoJSON geometry of a polygon or multi-polygon
 * @param b GeoJSON geometry of a polygon or multi-polygon
 * @returns GeoJSON geometry of the intersection of `a` and `b`. Can be null
 */
function computeIntersection(a, b) {
  const polyA = geoJsonGeometryToTurfPolygonOrMultiPolygon(a);
  const polyB = geoJsonGeometryToTurfPolygonOrMultiPolygon(b);
  const intersectionCoords = polyclip.intersection(
    polyA.geometry.coordinates,
    polyB.geometry.coordinates
  );
  if (intersectionCoords.length === 0) return null;
  return { type: 'MultiPolygon', coordinates: intersectionCoords };
}

function computeArea(feature) {
  const polygon = geoJsonGeometryToTurfPolygonOrMultiPolygon(feature);
  return turf.area(polygon);
}

/**
 * Computes the centroid of a polygon.
 * @param geometry GeoJSON geometry of a polygon.
 * @returns GeoJSON geometry
 */
function computeCentroid(geometry) {
  console.assert(
    geometry.type === 'Polygon' || geometry.type === 'MultiPolygon'
  );
  const poly = geoJsonGeometryToTurfPolygonOrMultiPolygon(geometry);
  const centroid = turf.center(poly);
  if (!centroid) return null;
  return centroid.geometry;
}

const crsConverter = proj4(proj4.WGS84, 'EPSG:3857');

/**
 * Computes the visual center of a polygon.
 * @param geometry GeoJSON geometry of a polygon.
 * @returns GeoJSON Point.
 */
function computeVisualCenter(geometry) {
  assert(geometry.type === 'Polygon');
  const points = geometry.coordinates[0].map(crsConverter.forward);
  const p = polylabel([points]);
  const [cx, cy] = [p[0], p[1]];
  const [cxt, cyt] = crsConverter.inverse([cx, cy]);
  return turf.point([cxt, cyt]).geometry;
}

/**
 * Computes a rectangle that encompasses all vertices.
 * @param geometry GeoJSON geometry of a polygon or multi-polygon.
 * @returns GeoJSON geometry
 */
function computeMinEnvelope(geometry) {
  const poly = geoJsonGeometryToTurfPolygonOrMultiPolygon(geometry);
  const envelope = turf.envelope(poly);
  return envelope.geometry;
}

/**
 * Computes the width of a rectangle.
 * @param geometry GeoJSON geometry of a polygon.
 * @returns [width, height] in meters
 */
function computeWidthHeight(geometry) {
  console.assert(geometry.type === 'Polygon');
  const c = geometry.coordinates[0];
  const l0 = turf.lineString([c[0], c[1]]);
  const l1 = turf.lineString([c[1], c[2]]);
  const width = turf.length(l0, { units: 'meters' });
  const height = turf.length(l1, { units: 'meters' });
  return [width, height];
}

/**
 * Runs a filter predicate on a polygon or each polygon of a multi-polygon.
 * @param geometry GeoJSON geometry of a polygon or multi-polygon.
 * @param filterFunc A function that takes a polygon and returns true if it should be kept.
 * @returns A multi-polygon containing only the geometries that passed the filter
 */
function filterPolygons(geometry, filterFunc) {
  const geoms =
    geometry.type === 'Polygon' ? [geometry.coordinates] : geometry.coordinates;
  const keepPolys = [];
  const rejectPolys = [];
  _.forEach((geom) => {
    if (filterFunc(geom)) {
      keepPolys.push(geom);
    } else {
      rejectPolys.push(geom);
    }
  }, geoms);
  console.log(keepPolys, rejectPolys);
  return turf.multiPolygon(keepPolys).geometry;
}

function computeBuffered(geometry, buffer) {
  return turf.buffer(geometry, buffer, { units: 'meters' }).geometry;
}

function computeTranslate(geometry, distance, direction = 0) {
  return turf.transformTranslate(geometry, distance, direction, {
    units: 'meters',
  });
}

function toPoint({ lng, lat }) {
  return turf.point([lng, lat]).geometry;
}

/**
 * Hash to geometry.
 */
function geoDecode(hash) {
  const [minLat, minLon, maxLat, maxLon] = geohash.decode_bbox(hash);
  return turf.bboxPolygon([minLon, minLat, maxLon, maxLat]).geometry;
}

function hasOverlap(a, b) {
  const polyA = geoJsonGeometryToTurfPolygonOrMultiPolygon(a);
  const polyB = geoJsonGeometryToTurfPolygonOrMultiPolygon(b);
  return turf.booleanIntersects(polyA, polyB);
}

export {
  computeArea,
  computeBuffered,
  computeCentroid,
  computeDifference,
  computeIntersection,
  computeMinEnvelope,
  computePerimeter,
  computeRectangleAroundLocation,
  computeSquarePolygonAroundLocation,
  computeSurroundingPolygonFromPolyline,
  computeTranslate,
  computeUnion,
  computeUnionMulti,
  computeVisualCenter,
  computeWidthHeight,
  filterPolygons,
  geoDecode,
  hasOverlap,
  toPoint,
};
