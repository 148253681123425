export default {
  user: {
    profile: '/user-profile',
    membershipManagement: '/user/membership/management',
    membershipChangeSuccess: '/user/membership/change/success',
  },
  login: '/login',
  logout: '/logout',
  signUp: '/signup',
  home: '/',
  dashboard: '/dashboard',
  order: {
    root: '/order',
    job: (jobId) => (jobId ? `/order/${jobId}` : '/order/:jobId'),
  },
  payment: {
    status: (jobId) =>
      jobId ? `/job/${jobId}/payment/status` : '/job/:jobId/payment/status',
    job: (jobId) => (jobId ? `/job/${jobId}/payment` : '/job/:jobId/payment'),
    assignedStatus: () => '/assigned-payment/status',
  },
  view3D: {
    root: '/viewer',
    project: (projectId) => `/viewer/${projectId ?? ':projectId'}`,
  },
  password: {
    create: '/create-password',
    reset: '/reset-password',
    forgot: '/forgot-password',
  },
  larkiWebsiteUrl: {
    home: process.env.LARKI_MARKETING_URL,
    products: `${process.env.LARKI_MARKETING_URL}/products`,
    pricing: `${process.env.LARKI_MARKETING_URL}/pricing`,
    aboutUs: `${process.env.LARKI_MARKETING_URL}/about`,
    contactUs: `${process.env.LARKI_MARKETING_URL}/contact-us`,
    privacy: `${process.env.LARKI_MARKETING_URL}/privacy-policy`,
    supplierTermsCond: `${process.env.LARKI_MARKETING_URL}/supplier-terms-conditions`,
    aerialProduct: `${process.env.LARKI_MARKETING_URL}/product/3d-aerial-point-cloud`,
    streetscapeProduct: `${process.env.LARKI_MARKETING_URL}/product/3d-streetscape-point-cloud`,
    droneProduct: `${process.env.LARKI_MARKETING_URL}/product/3d-drone-point-cloud-exterior-current-high-res-drone`,
    exteriorProduct: `${process.env.LARKI_MARKETING_URL}/product/3d-tripod-point-cloud-exterior-current-high-res`,
    interiorProduct: `${process.env.LARKI_MARKETING_URL}/product/3d-tripod-point-cloud-interior-current-high-res`,
    bimTerrainProduct: `${process.env.LARKI_MARKETING_URL}/product/bim-terrain`,
    featurePlans2D: `${process.env.LARKI_MARKETING_URL}/product/2d-feature-plan-exterior`,
    blog: `${process.env.LARKI_MARKETING_URL}/blog`,
    memberships: `${process.env.LARKI_MARKETING_URL}/memberships`,
    survey: process.env.LARKI_SURVEY_URL,
    custTermsCond: process.env.LARKI_URL_TERMS,
  },
  larkiSocialMedia: {
    instagram: process.env.LARKI_INSTAGRAM_URL,
    youtube: process.env.LARKI_YOUTUBE_URL,
    twitter: process.env.LARKI_TWITTER_URL,
    facebook: process.env.LARKI_FACEBOOK_URL,
    linkedin: process.env.LARKI_LINKEDIN_URL,
  },
  accessDenied: '/access-denied',
  quote: {
    accept: (quoteId) =>
      quoteId ? `/accept-quote/${quoteId}` : '/accept-quote/:quoteId',
  },
  fileManager: {
    root: '/file-manager',
    viewProject: (projectId) =>
      projectId ? `/file-manager/${projectId}` : '/file-manager/:projectId',
  },
  project: {
    acceptInvite: '/project/accept-invite/:projectId',
  },
  product: {
    search: '/product/search',
  },
  openBookmark: {
    path: '/open-bookmark/:shareToken',
    withShareToken: (shareToken) => `/open-bookmark/${shareToken}`,
  },
  requestAccess: {
    path: '/request-access/:shareToken',
    withShareToken: (shareToken) => `/request-access/${shareToken}`,
  },
  projectUser: {
    approve: {
      path: '/project/:projectId/user/:userId/approve',
      url: (projectId, userId) =>
        `/project/${projectId}/user/${userId}/approve`,
    },
    deny: {
      path: '/project/:projectId/user/:userId/deny',
      url: (projectId, userId) => `/project/${projectId}/user/${userId}/deny`,
    },
  },
};
