import _ from 'lodash/fp';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean, usePrevious } from 'react-use';

import useWorkerCallback from '../../hooks/useWorkerCallback';
import { setBoundaries, setUndefinedAddress } from '../../redux/actions';
import { isArrayEqual } from '../../utilities/lodash';
import {
  convertGMGeometryToGeoJson,
  hasPlaceStreetNumber,
} from '../../utilities/map';
import useGeocoder from '../RightMenu/useGeocoder';
import { DRAW_LAYERS_MERGED } from './constants';
import { computeIntersection, computeUnion } from './geometry';
import { MapViewContext } from './mapViewContext';

/**
 * `useMapRendering` is responsible for rendering coverage layers, selection regions, and boundaries (e.g. lot areas) on the map.
 */
const useMapRendering = ({ onContextMenuClick }) => {
  const dispatch = useDispatch();
  const { state, actions } = useContext(MapViewContext);
  const { mapState, jobsState } = useSelector((reduxState) => ({
    jobsState: reduxState.jobsReducer,
    mapState: reduxState.mapReducer,
  }));
  const [hasLoadedBoundaries, setHasLoadedBoundaries] = useBoolean(false);
  const { geocode, geocodeReady } = useGeocoder();

  const isBoundaryVisible = useMemo(
    () =>
      !(!jobsState.job || !jobsState.job?.lot_area_state) &&
      jobsState.job?.lot_area_state.visibility,
    [jobsState.job?.lot_area_state]
  );

  const previousSelections = usePrevious(state.selections);
  const previousCoverage = usePrevious(state.coverage);
  const previousBoundaries = usePrevious(mapState.boundaries);
  const previousIsBoundaryVisible = usePrevious(isBoundaryVisible); // mapState.isBoundaryVisible
  const previousMapState = usePrevious(state.currentMapState);
  const previousMapLoadCount = usePrevious(state.mapLoadCount);

  /**
   * Draw an individual layer.
   */
  const drawLayer = (
    layerGeometry,
    layerProductType,
    hovered,
    focused,
    selected,
    isHovered = false
  ) => {
    if (layerGeometry.type === 'Polygon') {
      state.mapManager.drawOverlayFromCoordinates(
        layerGeometry.coordinates,
        layerProductType,
        hovered,
        focused,
        selected,
        isHovered
      );
    } else if (layerGeometry.type === 'MultiPolygon') {
      _.forEach((polygon) => {
        if (!polygon) {
          console.warn('MultiPolygon contained empty polygon');
          return;
        }
        state.mapManager.drawOverlayFromCoordinates(
          polygon,
          layerProductType,
          hovered,
          focused,
          selected,
          isHovered
        );
      })(layerGeometry.coordinates);
    } else {
      console.error('Unknown geometry type:', layerGeometry.type);
    }
  };

  /* ---------- Draw selection products ---------- */

  const drawSelectionProductsWorker = useMemo(
    () =>
      new Worker(
        new URL(
          '../../workers/drawSelectionProducts.worker.js',
          import.meta.url
        )
      ),
    []
  );
  const [layersToDraw, setLayersToDraw] = useState([]);
  const drawSelectionProducts_ = useWorkerCallback(
    drawSelectionProductsWorker,
    (layers) => {
      setLayersToDraw(layers);
    }
  );
  useEffect(() => {
    if (!state.mapManager) {
      return;
    }
    state.mapManager.clearAvailableData();
    _.forEach(({ geometry, productType, hovered, focused, selected }) => {
      drawLayer(geometry, productType, hovered, focused, selected, false);
    }, layersToDraw);
    _.forEach((selection) => {
      if (selection.visible) {
        state.mapManager.drawSelectionRegion(
          selection,
          state.currentMapState,
          actions.makeSelectionActive,
          onContextMenuClick
        );
      }
    })(state.selections);
  }, [layersToDraw]);

  /* -------------------- */

  const drawHoveredProductLayers = (
    productCoverage,
    drawRegion,
    hovered,
    focused,
    selected
  ) => {
    if (DRAW_LAYERS_MERGED[productCoverage.category_name]) {
      let mergedLayerGeometry;
      _.forEach((layer) => {
        const layerGeometry = computeIntersection(layer.geometry, drawRegion);
        if (!layerGeometry) {
          return;
        }
        mergedLayerGeometry = mergedLayerGeometry
          ? computeUnion(mergedLayerGeometry, layerGeometry)
          : layerGeometry;
      })(productCoverage.layers);
      if (!mergedLayerGeometry) {
        console.warn('no merged layer geometry found');
        return;
      }
      drawLayer(
        mergedLayerGeometry,
        productCoverage.category_name,
        hovered,
        focused,
        selected,
        true
      );
    } else {
      _.forEach((layer) => {
        const layerGeometry = computeIntersection(layer.geometry, drawRegion);
        if (!layerGeometry) {
          return;
        }
        drawLayer(
          layerGeometry,
          layer.category_name,
          hovered,
          focused,
          selected,
          true
        );
      })(productCoverage.layers);
    }
  };

  /**
   * Draw coverage in the current region selection.
   */
  const drawHoveredProduct = () => {
    const hoveredProduct = _.find(['category_name', state.hoveredProductType])(
      state.coverageInRegionSelection
    );
    if (!hoveredProduct) {
      return;
    }
    if (_.isEmpty(state.debouncedRegionSelection)) {
      return null;
    }
    const region = _.values(state.debouncedRegionSelection)?.[0];
    if (_.isEmpty(region)) {
      return null;
    }
    const regionFeature = convertGMGeometryToGeoJson(region);
    drawHoveredProductLayers(hoveredProduct, regionFeature, true, false, true);
  };

  const drawBoundaries = () => {
    state.mapManager.drawDashedOverlay(
      mapState.boundaries.region?.coordinates[0] || []
    );
  };

  /**
   * Draw available products when hovered or in selections, and draw boundaries.
   */
  useEffect(() => {
    if (!state.mapManager) {
      return;
    }

    const canDrawHovered = !!(
      !state.hoveredProductType ||
      (state.hoveredProductType && !_.isEmpty(state.coverageInRegionSelection))
    );
    const canDrawSelections = !!(state.selections && state.coverage);
    const canDrawBoundaries = !!mapState.boundaries;

    const updateHovered = canDrawHovered;
    const updateSelections =
      canDrawSelections &&
      (!isArrayEqual(state.selections, previousSelections) ||
        !isArrayEqual(state.coverage, previousCoverage) ||
        !_.isEqual(state.currentMapState, previousMapState) ||
        state.mapLoadCount !== previousMapLoadCount);
    // !_.isEqual(state.mapManager, previousMapManager));
    const updateBoundaries =
      canDrawBoundaries &&
      (isBoundaryVisible !== previousIsBoundaryVisible ||
        !_.isEqual(mapState.boundaries, previousBoundaries));

    if (!updateHovered && !updateSelections && !updateBoundaries) {
      return;
    }

    if (canDrawHovered) {
      state.mapManager.clearHoveredData();
      drawHoveredProduct();
    }

    if (canDrawSelections) {
      drawSelectionProducts_({
        coverage: state.coverage,
        selections: state.selections,
        activeSelection: state.activeSelection,
        useCached: false,
      });
    }

    if (canDrawBoundaries) {
      state.mapManager.clearBoundariesData();
      if (isBoundaryVisible) {
        drawBoundaries();
      }
    }
  }, [
    state.hoveredProductType,
    // state.coverageInRegionSelection,
    state.selections,
    state.coverage,
    state.mapManager,
    state.mapLoadCount,
    // mapState.boundaries,
    state.currentMapState, // so that selections `onClick` event handler is updated
    isBoundaryVisible,
  ]);

  // Observe jobsState.job.lot_area_state to be used in template
  const lotAreaState = useMemo(() => {
    if (!jobsState.job || !jobsState.job?.lot_area_state) return null;
    return jobsState.job?.lot_area_state;
  }, [jobsState.job?.lot_area_state]);

  // Set mapState.boundaries on first load to trigger drawBoundaries()
  useEffect(() => {
    if (!hasLoadedBoundaries && jobsState.job && jobsState.job.user_order) {
      setHasLoadedBoundaries(true);
      dispatch(setBoundaries(jobsState.job.user_order?.property));
    }
  }, [hasLoadedBoundaries, jobsState.job]);

  // To handle undefined address
  // same existing condition in mapView.js -> handlePlaceChange
  useEffect(() => {
    if (!geocodeReady) {
      return;
    }

    if (state.address) {
      (async () => {
        const concatenatedAddress = Object.values(state.address)
          .filter((v) => !!v)
          .join(', ');
        const place = await geocode(concatenatedAddress);
        if (!hasPlaceStreetNumber(place)) {
          dispatch(setUndefinedAddress(true));
        }
      })();
    }
  }, [state.address, geocodeReady]);

  return {
    lotAreaState,
  };
};

export default useMapRendering;
