/* eslint-disable react/prop-types */
import classnames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useSearchParam } from 'react-use';
import { ANONYMOUS_ORDER } from '../../../constants/alert';
import { LEAD_CHECKOUT } from '../../../constants/job';
import { MEMBERSHIP } from '../../../constants/membership';
import { PRODUCT_TYPE } from '../../../constants/product';
import { sendLead, setMembershipPriceId } from '../../../redux/actions';
import {
  createJob,
  updateJob,
  updateProjectName,
} from '../../../redux/actions/jobs';
import { showModal } from '../../../redux/actions/modal';
import { profileSelectors } from '../../../redux/selectors/profile';
import routes from '../../../routes/constants';
import text, { formatCurrency } from '../../../text';
import { getNowDate } from '../../../utilities/date';
import { isMembershipPriceId } from '../../../utilities/membership';
import {
  isStaff,
  isUserAnonymous,
  isUserUnderTrial,
  userHasValidMembership,
} from '../../../utilities/user';
import { SAVE_DRAFT_MODAL } from '../../modal/SaveDraftModal';
import { FORCE_JOB_UPDATE_MODAL } from '../../modal/forceJobUpdateModal';
import { STATUS } from '../../orderListing/consts';
import { MapViewContext } from '../mapViewContext';
import { ExpandedDetails } from './ExpandedDetails';
import { amplitudeTrack } from '../../../utilities';
import { AMPLITUDE_EVENTS } from '../../../constants/analytics';

const { PAYMENT_FLOW } = AMPLITUDE_EVENTS;

const ExpandableCheckoutBox = ({ isReadOnly }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mergeProjectId = useSearchParam('mergeProjectId');
  const isVisibleFor2DMap = useRouteMatch([
    routes.order.root,
    routes.order.job(),
  ]);
  const [expandInvoice, setExpandInvoice] = useState(false);
  const { state } = useContext(MapViewContext);

  const { currentUser, membershipPriceId, mapState } = useSelector(
    (reduxState) => ({
      currentUser: reduxState.profileReducer.userProfile,
      membershipPriceId: reduxState.order.membershipPriceId,
      mapState: reduxState.mapReducer,
    })
  );

  const isAdmin = useSelector(profileSelectors.getIsAdmin);

  const handleSaveDraft = async (latestJob, values, force = false) => {
    const data = {
      address: {
        ...state.address,
      },
      message: state.requestMessage,
      ...values,
    };
    if (values?.projectName && latestJob.project?.id) {
      await dispatch(
        updateProjectName(latestJob.project.id, values.projectName, isAdmin)
      );
    }

    const job = await dispatch(
      latestJob?.id
        ? updateJob({
            ...data,
            jobId: latestJob.id,
            updatedAt: getNowDate(),
            force,
            onConflict: onShowForceSave,
          })
        : createJob(data)
    );

    return job;
  };

  const handleSubmit = async (job) => {
    if (job) {
      const params = new URLSearchParams();
      if (mergeProjectId) {
        params.append('mergeProjectId', mergeProjectId);
      }
      let redirectUrl = routes.payment.job(job.id);

      if (isUserAnonymous(currentUser)) {
        redirectUrl = routes.order.job(job.id);
        params.append('alertType', ANONYMOUS_ORDER);
        params.append('redirectUrl', redirectUrl);

        history.push({
          pathname: routes.login,
          search: params.toString(),
        });
      } else {
        dispatch(sendLead(job.id, LEAD_CHECKOUT));
        amplitudeTrack(PAYMENT_FLOW.EVENT, {
          action: PAYMENT_FLOW.CHECKOUT_CART,
        });
        history.push({
          pathname: redirectUrl,
          search: params.toString(),
        });
      }
    }
  };

  const onSaveDraft = () => {
    dispatch(
      showModal(SAVE_DRAFT_MODAL, {
        onSubmit: handleSaveDraft,
        currentUser,
      })
    );
  };

  const onSaveAndExit = async () => {
    if (state.project.name) {
      const job = await handleSaveDraft(state.job, {
        projectName: state.project.name,
      });

      if (job) {
        history.push(routes.home);
      }
    } else {
      onSaveDraft();
    }
  };

  const onForceSaveAndExit = async () => {
    const job = await handleSaveDraft(
      state.job,
      {
        projectName: state.project.name,
      },
      true
    );

    if (job) {
      history.push(routes.dashboard);
    }
  };

  const onShowForceSave = () => {
    dispatch(
      showModal(FORCE_JOB_UPDATE_MODAL, {
        onSubmit: onForceSaveAndExit,
      })
    );
  };

  const isJobAwaitingPayment = [
    STATUS.COMMITTED,
    STATUS.AWAITING_PAYMENT,
  ].includes(state.job?.status);

  const chargesHasTbc = useMemo(
    () =>
      state.job?.quote.charges?.some(({ details }) =>
        [PRODUCT_TYPE.UNKNOWN].includes(details.delivery_method)
      ),
    [state.job?.quote?.charges]
  );

  const grandTotal = useMemo(() => {
    const quote = state.job?.quote;
    let order;
    if (quote?.cart_totals) {
      // for new orders Sprint 67
      order = quote.cart_totals;
    } else if (quote?.order) {
      // released Sprint 66
      order = quote.order;
    } else {
      // old implementation
      // used quoted and completed orders
      order = quote;
    }

    return order?.price.base && !chargesHasTbc
      ? formatCurrency(
          isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role)
            ? order?.price.member_grand_total
            : order?.price.grand_total,
          quote?.currency.name,
          quote?.currency.scale_factor,
          {},
          true
        )
      : text('toBeConfirmed');
  }, [chargesHasTbc, currentUser.role, membershipPriceId, state.job?.quote]);

  // RESET membershipPriceId IF USER IS TRIAL
  useEffect(() => {
    if (
      (currentUser && isStaff(currentUser.role)) ||
      (userHasValidMembership(currentUser) && isUserUnderTrial(currentUser))
    ) {
      dispatch(setMembershipPriceId(MEMBERSHIP.NO_MEMBERSHIP));
    }
  }, [currentUser, dispatch]);

  return (
    <div
      className={classnames('ExpandedCheckoutBox', {
        'read-only': isReadOnly,
        expanded: expandInvoice,
      })}
    >
      {state.job?.quote ? (
        <ExpandedDetails
          membershipPriceId={membershipPriceId}
          toggleIcon='caret-up'
          toggleInvoiceSummary={() => setExpandInvoice(!expandInvoice)}
          isInvoiceExpanded={expandInvoice}
          hasTbc={chargesHasTbc}
        />
      ) : null}

      <div className='mb-2' />

      {!isReadOnly ? (
        <div className='expandedCheckoutBoxFooter'>
          {isVisibleFor2DMap ? (
            <Button
              style={{
                width: 100,
              }}
              id='dashboardButton'
              variant='outline-primary'
              onClick={onSaveAndExit}
              disabled={state.isCartLoading}
            >
              {text(
                mapState.isLoadingSmartSelections
                  ? 'saving'
                  : 'saveAndGoToDashboard'
              )}
            </Button>
          ) : null}
          <Button
            id='orderDataButton'
            data-toggle='modal'
            data-target='#purchase'
            onClick={() => handleSubmit(state.job)}
            disabled={state.isCartLoading}
          >
            {`${grandTotal} - ${text('checkout')}`}
          </Button>
        </div>
      ) : (
        <Button
          className='full-width'
          onClick={() =>
            history.push({
              pathname: isJobAwaitingPayment
                ? routes.payment.job(state.job.id)
                : routes.dashboard,
            })
          }
        >
          {text(isJobAwaitingPayment ? 'payNow' : 'goToDashboard')}
        </Button>
      )}
    </div>
  );
};

export default ExpandableCheckoutBox;
