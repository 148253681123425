import classnames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { usePreviousDistinct } from 'react-use';

import classNames from 'classnames';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CART_TABLE } from '../../constants/table';
import { RESET_FORM_DELAY } from '../../pages/constants';
import text, { formatSqmArea } from '../../text';
import { selectionNameSchema } from '../../validation/job';
import Icon from '../icon/Icon';
import { PRODUCT_DATA } from '../mapView/constants';
import LayerMeatballDropdown from '../mapView/layerMeatballDropdown/LayerMeatballDropdown';
import { MapViewContext } from '../mapView/mapViewContext';
import ShapeMenuModal from '../modal/ShapeMenuModal';
import Cell from '../table/Cell';

const { COLUMN } = CART_TABLE;

const SQM_IN_SQKM = 1e6;

const SimpleDataRow = ({
  data,
  coverage,
  index,
  useMeatballDropdown,
  showClearWhenShapeSelected = true,
  openedMenuId = {
    get: null,
    set: () => null,
  },
}) => {
  const { state, actions } = useContext(MapViewContext);
  const previousName = usePreviousDistinct(data.name);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasInitialValueLoaded, seHasInitialValueLoaded] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const shapeMenuRef = useRef(null);
  const dataName = data.name ?? `Shape ${index + 1}`;

  const { isQuotingJob } = useSelector((reduxState) => ({
    isQuotingJob: reduxState.jobsReducer.isQuotingJob,
  }));
  const layerMeatballDropdown = useRef();

  const shapeMenuIsOpened = useMemo(() => {
    return openedMenuId.get == data.id;
  }, [openedMenuId.get, data.id]);

  const debounceSetIsSuccess = useCallback(
    debounce((value) => setIsSuccess(value), RESET_FORM_DELAY)
  );
  
  const shapeArea = useMemo(() => {
    if (coverage?.area_in_sqm) {
      if (coverage.area_in_sqm > SQM_IN_SQKM) {
        return `${(coverage.area_in_sqm / SQM_IN_SQKM).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }
        )} km²`;
      }
      return formatSqmArea(coverage.area_in_sqm);
    }
    return '';
  }, [coverage]);

  const handleClick = (event) => {
    if (
      layerMeatballDropdown.current?.contains(event.target) ||
      shapeMenuIsOpened
    ) {
      return;
    }

    actions.makeSelectionActive(data, showClearWhenShapeSelected);
  };

  const selectedColour = (() => {
    return PRODUCT_DATA.entities[data.category_name]?.display_color;
  })();

  const closeShapeMenu = () => {
    openedMenuId.set(null);
  };

  useEffect(() => {
    seHasInitialValueLoaded(true);
  }, []);

  useEffect(() => {
    if (data.name !== previousName && hasInitialValueLoaded) {
      setIsSuccess(true);
    }
    debounceSetIsSuccess(false);
  }, [previousName, data.name]);

  return (
    <>
      <tr
        className={classnames('SimpleDataRow ', {
          isCollapsed: data.isCollapsed,
          isFocused: data.focused,
          [data.category_name]: data.category_name,
        })}
        style={{
          backgroundColor:
            state.hoveredData?.selected && state.hoveredData?.id === data.id
              ? `${selectedColour}44`
              : '#FFFFFF',
        }}
        onMouseEnter={(e) => {
          if (e.target.id !== 'ViewCell' && data.visible) {
            actions.setHoveredData({ id: data.id, selected: true });
          }
        }}
        onMouseLeave={() => {
          if (data.visible) {
            actions.setHoveredData(null);
          }
        }}
        onClick={handleClick}
        ref={shapeMenuRef}
      >
        <Cell
          {...(isNameFocused && { colSpan: 6 })}
          className={classNames(COLUMN.POINT_CLOUD, {
            expanded: isNameFocused,
          })}
          validationSchema={selectionNameSchema}
          isSuccessful={isSuccess}
          value={dataName}
          disabled={isQuotingJob}
          onSubmit={(name) => {
            actions.setSelections(
              state.selections.map((selection) => ({
                ...selection,
                name: data.id === selection.id ? name : selection.name,
              }))
            );
          }}
          onFocus={() => {
            setIsNameFocused(true);
          }}
          onBlur={() => setIsNameFocused(false)}
        >
          <div
            className='product-light'
            style={{
              borderRadius: '50%',
              backgroundColor: selectedColour,
            }}
          />
        </Cell>

        {!isNameFocused && (
          <>
            <td
              className={classNames(COLUMN.AREA, 'text-left pl-3')}
              colSpan={3}
            >
              {shapeArea}
            </td>

            <td className={COLUMN.VISIBILITY} align='right'>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement='top'
                delay={{
                  show: 500,
                  hide: 0,
                }}
                overlay={
                  <Tooltip>
                    {text(data.visible ? 'hideShape' : 'showShape')}
                  </Tooltip>
                }
              >
                <Button
                  variant='light btn-eye click-outside-excluded'
                  onClick={(ev) => {
                    ev.stopPropagation();
                    actions.toggleSelectionVisibility(data.id);
                  }}
                >
                  <Icon icon={data.visible ? 'eye' : 'eye-slash'} />
                </Button>
              </OverlayTrigger>
            </td>

            <td className={COLUMN.MEATBALL} align='right'>
              {useMeatballDropdown ? (
                <LayerMeatballDropdown
                  data={data}
                  ref={layerMeatballDropdown}
                />
              ) : (
                <Button
                  variant='light btn-more click-outside-excluded'
                  onClick={(ev) => {
                    ev.stopPropagation();
                    if (shapeMenuIsOpened) {
                      openedMenuId.set(null);
                      return;
                    }

                    openedMenuId.set(data.id);
                  }}
                >
                  <Icon style={{ pointerEvents: 'none' }} icon='more' />
                </Button>
              )}
            </td>
          </>
        )}

        {shapeMenuIsOpened && (
          <ShapeMenuModal
            ref={shapeMenuRef}
            shapeData={data}
            shapeName={dataName}
            closeMenu={closeShapeMenu}
          />
        )}
      </tr>
    </>
  );
};

SimpleDataRow.propTypes = {
  data: PropTypes.object.isRequired,
  coverage: PropTypes.object,
  index: PropTypes.number.isRequired,
  useMeatballDropdown: PropTypes.bool,
  showClearWhenShapeSelected: PropTypes.bool,
};

SimpleDataRow.defaultProps = {
  useMeatballDropdown: true,
};

export default SimpleDataRow;
