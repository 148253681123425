import React from 'react';
import { TOUR_DONE_MODAL } from '../../../../../components/modal/TourDoneModal';
import { showModal } from '../../../../../redux/actions';
import { TOGGLE_RIGHT_MENU_VISIBILITY } from '../../../../../redux/constants/layout';
import text from '../../../../../text';
import { store } from '../../../../../utilities/store';
import { StepContent } from './TourContent';
import { hightlightedAreaStyling, popoverCustomDefaultStyling } from './config';

const isOverflown = (element) => {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
};

export const TOUR_2D_MAP = [
  {
    selector: '#pac-input',
    content: (
      <StepContent
        title={text('tour2dStep1Title')}
        description={text('tour2dStep1')}
      />
    ),
    position: 'bottom',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginTop: '16px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'top',
    },
  },
  {
    selector: '.draw-menu',
    content: (
      <StepContent
        title={text('tour2dStep2Title')}
        description={text('tour2dStep2')}
      />
    ),
    position: 'right',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginLeft: '16px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'left',
    },
  },
  {
    selector: '.ProductControls',
    content: (
      <StepContent
        title={text('tour2dStep3Title')}
        description={text('tour2dStep3')}
      />
    ),
    position: 'left',
    styles: {
      popover: (base) => {
        const { top, ...rest } = popoverCustomDefaultStyling(base);
        const _isOverFlown = isOverflown(
          document.querySelector('.ProductControls')
        );
        const marginLeft = _isOverFlown ? '106px' : '98px';
        const clientTop = document
          .querySelector('.ProductControls')
          .getBoundingClientRect().top;

        return {
          ...rest,
          marginLeft,
          top: `${clientTop - 154}px`,
        };
      },
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'left',
    },
  },
  {
    selector: '.wrapper',
    content: (
      <StepContent
        title={text('tour2dStep4Title')}
        description={text('tour2dStep4')}
      />
    ),
    position: 'left',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginLeft: '-20px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'right',
      caretPosY: 'xs',
      callback: {
        next: () => {
          store.dispatch({
            type: TOGGLE_RIGHT_MENU_VISIBILITY,
            payload: true,
          });
        },
      },
      stepDelay: 300,
    },
  },
  {
    selector: '.RightMenuV2',
    content: (
      <StepContent title={text('tour2dStep5Title')} description={''}>
        <p className='mb-3'>{text('tour2dStep5_1')}</p>
        <p className='mb-3'>{text('tour2dStep5_2')}</p>
        <p className='mb-0'>{text('tour2dStep5_3')}</p>
      </StepContent>
    ),
    position: 'left',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginLeft: '-20px',
        maxWidth: '30%',
        top: `-30%`,
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'right',
      callback: {
        next: () => {
          store.dispatch(showModal(TOUR_DONE_MODAL));
        },
      },
    },
  },
];
