import { AxiosError } from 'axios';

import { DOWNLOAD_ALL_JOB_STATUS } from '../../constants/asset';
import text from '../../text';
import { client } from '../../utilities/api';
import { captureError } from '../../utilities/error';
import {
  DOWNLOAD_ALL_STATUS,
  DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID,
  DOWNLOAD_ALL_UPDATE_STATUS,
  DOWNLOAD_ALL_URL,
  DOWNLOAD_ALL_URL_FAILURE,
  DOWNLOAD_ALL_URL_SUCCESS,
  GET_CURRENT_PROJECT,
  GET_CURRENT_PROJECT_FAILURE,
  GET_CURRENT_PROJECT_SUCCESS,
  LIST_PROJECTS,
  LIST_PROJECTS_FAILURE,
  LIST_PROJECTS_SUCCESS,
  LIST_PROJECT_USERS,
  LIST_PROJECT_USERS_SUCCESS,
  REGISTER_PROJECT_TRIAL_MEMBERS,
  REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS,
  SET_CURRENT_PROJECT,
  UPDATE_USER_PROJECT_TRIAL,
  UPDATE_USER_PROJECT_TRIAL_SUCCESS,
} from '../constants/project';
import { alertError, alertWarning } from './alert';
import { processJobList } from './jobs';
import { jobsConstants } from '../constants';

export const generateDownloadAllUrl = (projectId) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_ALL_URL,
      payload: { projectId },
    });

    const { data } = await client.generateDownloadAllUrl(projectId);

    dispatch({
      type: DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID,
      payload: { projectId, queueJobId: data.jobId },
    });

    switch (data.status) {
      case DOWNLOAD_ALL_JOB_STATUS.AVAILABLE:
        dispatch({
          type: DOWNLOAD_ALL_URL_SUCCESS,
          payload: { projectId },
        });
        break;
      case DOWNLOAD_ALL_JOB_STATUS.UNAVAILABLE:
        dispatch({
          type: DOWNLOAD_ALL_URL_FAILURE,
          payload: { projectId },
        });
        dispatch(alertWarning(text('downloadAllUnavailable')));
        break;
      case DOWNLOAD_ALL_JOB_STATUS.FAILED:
        dispatch({
          type: DOWNLOAD_ALL_URL_FAILURE,
          payload: { projectId },
        });
        dispatch(alertError(text('downloadAllFailed')));
        break;
      case DOWNLOAD_ALL_JOB_STATUS.PROCESSING:
        break;
      default:
        console.error(`Unknown download all job status: ${data.status}`);
        break;
    }

    return data;
  } catch (error) {
    captureError(error);

    dispatch({
      type: DOWNLOAD_ALL_URL_FAILURE,
      payload: { projectId },
    });

    dispatch(
      alertError(
        error.response?.data?.message
          ? error.response.data.message
          : text('downloadAllFailed')
      )
    );
  }
};

export const getDownloadAllUrl = (projectId, jobId) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_ALL_URL,
      payload: { projectId },
    });
    const { data } = await client.getDownloadAllUrl(projectId, jobId);
    switch (data.status) {
      case DOWNLOAD_ALL_JOB_STATUS.AVAILABLE:
        dispatch({
          type: DOWNLOAD_ALL_URL_SUCCESS,
          payload: { projectId },
        });
        break;
      case DOWNLOAD_ALL_JOB_STATUS.FAILED:
        dispatch({
          type: DOWNLOAD_ALL_URL_FAILURE,
          payload: { projectId },
        });
        dispatch(alertError('Failed to generate download URL'));
        break;
      case DOWNLOAD_ALL_JOB_STATUS.PROCESSING:
        dispatch({
          type: DOWNLOAD_ALL_UPDATE_STATUS,
          payload: {
            projectId,
            status:
              data.zipProgress === 1
                ? DOWNLOAD_ALL_STATUS.DOWNLOADING
                : DOWNLOAD_ALL_STATUS.ZIPPING,
          },
        });
        break;
      case DOWNLOAD_ALL_JOB_STATUS.UNAVAILABLE:
      default:
        dispatch({
          type: DOWNLOAD_ALL_UPDATE_STATUS,
          payload: {
            projectId,
            status: DOWNLOAD_ALL_STATUS.IDLE,
          },
        });
        console.error(`Unknown download all job status: ${data.status}`);
        break;
    }
    return data;
  } catch (error) {
    captureError(error);

    dispatch({
      type: DOWNLOAD_ALL_URL_FAILURE,
      payload: { projectId },
    });
    dispatch(
      alertError(
        error.response?.data?.message
          ? error.response.data.message
          : text('downloadAllFailed')
      )
    );
  }
};

export const resumeDownloadAll = (projectId) => async (dispatch) => {
  try {
    const { data: queueJobId } = await client.toResumeDownload(projectId);
    if (queueJobId) {
      dispatch({
        type: DOWNLOAD_ALL_URL,
        payload: { projectId },
      });
      dispatch({
        type: DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID,
        payload: { projectId, queueJobId },
      });
    }
  } catch (err) {
    dispatch({
      type: DOWNLOAD_ALL_URL_FAILURE,
      payload: { projectId },
    });
    if (!(err instanceof AxiosError && err.response.status === 403)) {
      captureError(err);
      dispatch(
        alertError(
          err.response?.data?.message
            ? err.response.data.message
            : text('downloadAllFailed')
        )
      );
    }
  }
  return {};
};

export const setCurrentProject = (project) => async (dispatch) =>
  dispatch({
    type: SET_CURRENT_PROJECT,
    payload: { project },
  });

export const getProjectById =
  (projectId, { includeJobs, includePointCloud } = {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_CURRENT_PROJECT });
      const { data } = await client.getProjectById(projectId, {
        includeJobs,
        includePointCloud,
      });
      dispatch({
        type: GET_CURRENT_PROJECT_SUCCESS,
        payload: { project: { ...data, projectId: data.id } },
      });
      return { project: data };
    } catch (err) {
      dispatch({ type: GET_CURRENT_PROJECT_FAILURE });
      if (!(err instanceof AxiosError && err.response.status === 403)) {
        captureError(err);
      }
    }
  };

export const getLatestProject =
  ({ isAdmin, includeDemo, includeJobs, includePointCloud } = {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_CURRENT_PROJECT });
      const { data } = await client.getLatestProject({
        isAdmin,
        inviteStatus: ['accepted'],
        includeDemo,
        includeJobs,
        includePointCloud,
      });
      dispatch({
        type: GET_CURRENT_PROJECT_SUCCESS,
        payload: { project: { ...data, projectId: data.id } },
      });
      return data;
    } catch (err) {
      console.error(err);
      dispatch({ type: GET_CURRENT_PROJECT_FAILURE });
    }
  };

export const registerProjectTrialMembers =
  (projectId, users) => async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_PROJECT_TRIAL_MEMBERS,
        payload: { projectId },
      });

      const { data } = await client.registerProjectTrialMembers(
        projectId,
        users
      );

      dispatch({
        type: REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS,
        payload: { members: data },
      });

      return data;
    } catch (error) {
      dispatch(alertError(error.response.data.message));
    }
  };

export const listProjects =
  (isAdmin = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: LIST_PROJECTS });
      const { data } = await client.listProjects({
        isAdmin,
        includeDemo: false,
        inviteStatus: ['accepted'],
      });

      const dispatchType = {
        list: isAdmin
          ? jobsConstants.SET_ADMIN_JOB_LIST
          : jobsConstants.SET_JOBS_LIST,
        count: isAdmin
          ? jobsConstants.SET_ADMIN_STATUS_COUNT
          : jobsConstants.SET_JOB_STATUS_COUNTS,
      };

      dispatch({
        type: dispatchType.list,
        jobsList: processJobList(data),
      });

      dispatch({
        type: dispatchType.count,
        statusCounts: data.statusCounts,
      });

      dispatch({
        type: LIST_PROJECTS_SUCCESS,
        payload: { data },
      });
    } catch (error) {
      let message = error.response?.data?.message || text('listProjectsFailed');
      captureError(error);
      dispatch(alertError(message));
      dispatch({
        type: LIST_PROJECTS_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  };

export const listProjectUsers = (projectId) => async (dispatch) => {
  try {
    dispatch({
      type: LIST_PROJECT_USERS,
      payload: { projectId },
    });

    const { data } = await client.listProjectUsers(projectId);

    dispatch({
      type: LIST_PROJECT_USERS_SUCCESS,
      payload: { projectId, users: data },
    });
  } catch (error) {
    dispatch(alertError(error.response.data.message));
  }
};

export const updateUserProjectTrial =
  (projectId, userId, projectRole) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USER_PROJECT_TRIAL,
        payload: { projectId, userId },
      });

      await client.updateUserProjectTrial(projectId, userId, projectRole);

      dispatch({
        type: UPDATE_USER_PROJECT_TRIAL_SUCCESS,
        payload: { projectId, userId },
      });
    } catch (error) {
      dispatch(alertError(error.response.data.message));
    }
  };
