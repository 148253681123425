/* eslint-disable react/prop-types */

import JSZip from 'jszip';
import { map } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';

import { SVG } from '@svgdotjs/svg.js';
import text from '../../../text';
import { client } from '../../../utilities/api';
import { downloadFile } from '../../../utilities/files';
import mapScreenshotUtils from '../../../utilities/mapScreenshot';
import { GEOSPATIAL_FILES } from '../constants';
import { MapViewContext } from '../mapViewContext';

const toFileNameSafe = (s) => {
  return s.replace(/[^a-z0-9]/gi, '_').toLowerCase();
};

const downloadGeospatialFile = async (jobId, selections, fileType) => {
  const urls = [];
  const names = [];
  // Promise.all would be more elegant here
  for (const selection of selections) {
    const { data } = await client.getSelectionFeaturesFile(
      jobId,
      [selection.id],
      fileType
    );
    const fileName = selection.name
      ? toFileNameSafe(selection.name)
      : selection.id;
    urls.push(data);
    names.push(`${fileName}.${fileType}`);
  }
  if (urls.length === 1) {
    downloadFile(urls[0]);
  } else if (urls.length > 1) {
    const zip = new JSZip();
    for (let i = 0; i < urls.length; i++) {
      const res = await fetch(urls[i]);
      const blob = await res.blob();
      zip.file(names[i], blob, { blob: true });
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      downloadFile(URL.createObjectURL(content), 'shapes.zip');
    });
  }
};

const DownloadsMenu = ({ handleCloseMenu, selections }) => {
  const { state } = useContext(MapViewContext);

  // todo: run in worker thread
  const handleScreenshot = useCallback(() => {
    selections.forEach(async (selection) => {
      const mapDim = {
        width: state.mapRef.current.offsetWidth,
        height: state.mapRef.current.offsetHeight,
      };
      const { layerPaths, bboxXy, mapTilesXy, labelsXy } =
        await mapScreenshotUtils.computeMapScreenshot(
          state.map,
          mapDim,
          selection,
          state.coverage
        );
      const draw = SVG();
      const group = draw.group();
      mapScreenshotUtils.createSvgPath(
        group,
        layerPaths,
        selection.category_name
      );
      draw.width(bboxXy.xMax - bboxXy.xMin);
      draw.height(bboxXy.yMax - bboxXy.yMin);
      const downloadUrl = await mapScreenshotUtils.drawMapScreenshot(
        draw,
        mapTilesXy,
        labelsXy
      );
      const fileName = `${mapScreenshotUtils.toValidFileStem(
        selection.name
      )}.jpg`;
      downloadFile(downloadUrl, fileName);
    });
    handleCloseMenu();
  }, [selections, state.mapRef, state.map, state.coverage, handleCloseMenu]);

  return (
    <div className='geospatial-downloads-menu'>
      <Dropdown.Item onClick={handleScreenshot}>
        {text('jpgScreenshot')}
      </Dropdown.Item>
      {map(GEOSPATIAL_FILES, (shapeFile, key) => (
        <Dropdown.Item
          key={key}
          onClick={async () => {
            handleCloseMenu();

            if (!selections || selections.length === 0) {
              return;
            }
            await downloadGeospatialFile(
              state.job?.id,
              selections,
              shapeFile.fileExtension
            );
          }}
        >
          {shapeFile.displayName}
        </Dropdown.Item>
      ))}
    </div>
  );
};

export default DownloadsMenu;
