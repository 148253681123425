import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useAsyncFn } from 'react-use';

import { Button } from '../../components/button';
import Page from '../../components/page/Page';
import useOpenBookmark, {
  OpenBookmarkRejectedError,
} from '../../hooks/useOpenBookmark';
import { alertError } from '../../redux/actions';
import { profileSelectors } from '../../redux/selectors/profile';
import text from '../../text';
import { client } from '../../utilities/api';
import { captureError } from '../../utilities/error';

const RequestAccessPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { shareToken } = useParams();
  const user = useSelector(profileSelectors.getUserProfile);

  const [requestAccessState, requestAccess] = useAsyncFn(async () => {
    try {
      const resp = await client.requestAccess(shareToken);
      return resp.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 400) {
          return err.response.data;
        }
      }
      throw err;
    }
  }, [shareToken]);

  const [openBookmarkState, openBookmark] = useOpenBookmark(shareToken, false);

  const [isRejected, setIsRejected] = useState(false); // was access denied?

  const handleRequestAccessClicked = async () => {
    await requestAccess();
  };

  useEffect(() => {
    if (requestAccessState.error) {
      captureError(requestAccessState.error);
      dispatch(alertError(requestAccessState.error.message));
    }
  }, [requestAccessState.error, dispatch]);

  useEffect(() => {
    (async () => {
      const data = await openBookmark();
      if (data?.redirectPathname) {
        history.push(data.redirectPathname);
      }
    })();
  }, [history, openBookmark]);

  useEffect(() => {
    const err = openBookmarkState.error;
    if (!err) return;
    if (err instanceof OpenBookmarkRejectedError) {
      setIsRejected(true);
    } else {
      captureError(err);
      dispatch(alertError(err.message));
    }
  }, [dispatch, openBookmarkState.error]);

  return (
    <Page title='Request Access'>
      <div className='request-access__container'>
        <div className='request-access__text-content'>
          {isRejected ? (
            <>
              <h1>{text('accessDenied')}</h1>
              <p className='request-access__fail'>
                {text('accessDeniedDescription')}
              </p>
            </>
          ) : (
            <>
              <h1>{text('youNeedAccess')}</h1>
              {!requestAccessState.loading &&
              !requestAccessState.error &&
              requestAccessState.value ? (
                <>
                  <p className='request-access__success mb-lg'>
                    {text('accessRequestSent')}
                    <br />
                    {text('accessRequestEmail')}
                  </p>
                  {user ? (
                    <>
                      <p className='mb-md'>{text('profileRequestingAccess')}</p>
                      <p>
                        {user.full_name}
                        <br />
                        {user.email}
                      </p>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <p>{text('youNeedToBeInvited')}</p>
                  <Button
                    onClick={handleRequestAccessClicked}
                    loading={requestAccessState.loading}
                    className='request-access__button'
                  >
                    {text('requestAccess')}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
        <ReactSVG
          src='/public/img/request-access.svg'
          className='request-access__img'
        />
      </div>
    </Page>
  );
};

export default RequestAccessPage;
