import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import Page from '../../../components/page/Page';
import { MEMBERSHIP } from '../../../constants/membership';
import { setMembershipPriceId } from '../../../redux/actions';
import text from '../../../text';
import { getUserMembership } from '../../../utilities/user';
import MembershipInvoice from '../Invoice/MembershipInvoice';
import MembershipManagementWizard from '../MembershipWizard/MembershipManagementWizard';

const MembershipManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));

  useEffect(() => {
    const membershipInfo = getUserMembership(currentUser);
    let membershipPriceId;
    if (!membershipInfo && !isEmpty(currentUser)) {
      membershipPriceId = MEMBERSHIP.MONTHLY.PRICE_ID;
    } else {
      membershipPriceId = membershipInfo?.price_id;
    }

    dispatch(setMembershipPriceId(membershipPriceId));
  }, [currentUser, dispatch]);

  return (
    <Page className='MembershipManagement'>
      <div className='invoice-section'>
        <Button variant='link' onClick={() => history.goBack()}>
          <Icon icon='caret-left' />
          {text('back')}
        </Button>
        <MembershipInvoice />
      </div>
      <div className='section'>
        <MembershipManagementWizard />
      </div>
    </Page>
  );
};

export default MembershipManagement;
