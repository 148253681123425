import _ from 'lodash/fp';

import { PRODUCT_DATA } from '../components/mapView/constants';
import {
  computeIntersection,
  computeUnion,
} from '../components/mapView/geometry';
import { filterProductCoverageLayersByIntersection } from '../utilities/coverage';
import { selectionToGeoJsonNoGM } from './utilities';

export const getCoverageLabels = ({ coverage, selections }) => {
  const labels = [];

  const addLabel = (layer) => {
    layer.marker_locations.forEach((position) => {
      labels.push({
        position,
        displayColor: PRODUCT_DATA.entities[layer.category_name].display_color,
        dateAcquired: layer.acquired_at,
      });
    });
  };

  const selectionsByCategoryName = _.groupBy('category_name')(selections);
  _.forEach((productCoverage) => {
    const productSelections = _.filter(
      'visible',
      selectionsByCategoryName[productCoverage.category_name]
    );
    if (_.isEmpty(productSelections)) {
      return;
    }
    let drawRegion;
    _.forEach((selection) => {
      const selectionRegion = selectionToGeoJsonNoGM(selection).geometry;
      if (!drawRegion) {
        drawRegion = selectionRegion;
      } else {
        drawRegion = computeUnion(drawRegion, selectionRegion);
      }
    }, productSelections);
    if (!drawRegion) {
      return;
    }
    productCoverage = filterProductCoverageLayersByIntersection(
      productCoverage,
      drawRegion
    );
    const layers = _.filter('acquired_at', productCoverage.layers);
    if (_.isEmpty(layers)) {
      return;
    }
    _.forEach((layer) => {
      const layerGeometry = computeIntersection(layer.geometry, drawRegion);
      if (!layerGeometry) {
        return;
      }
      addLabel(layer);
    }, layers);
  }, coverage);

  return labels;
};

self.onmessage = (e) => {
  const { coverage, selections } = e.data;
  self.postMessage(getCoverageLabels({ coverage, selections }));
};

export {};
