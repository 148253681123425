import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import T from 'prop-types';
import { useParams } from 'react-router-dom';
import WhiteContainer from '../../../components/containers/WhiteContainer';
import List from '../../../components/list/List';
import Loading from '../../../components/loading/Loading';
import { MapViewContext } from '../../../components/mapView/mapViewContext';
import { getMembershipPrices, quoteOrder } from '../../../redux/actions';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';

const MembershipInvoice = ({ numOrder = 1, isFromOrderWizard = false }) => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const {
    state: { buyNowData },
  } = useContext(MapViewContext);
  const { membershipPriceId, membershipPrices } = useSelector((state) => ({
    membershipPriceId: state.order.membershipPriceId,
    membershipPrices: state.profileReducer.membershipPrices,
  }));

  const selectedMembership = useMemo(() => {
    if (membershipPrices.value) {
      return membershipPrices.value.find(
        (membership) => membership.id === membershipPriceId
      );
    }
    return {};
  }, [membershipPriceId, membershipPrices]);

  useEffect(() => {
    if (membershipPriceId) {
      dispatch(getMembershipPrices());
      if (jobId) {
        dispatch(
          quoteOrder(jobId, undefined, !isMembershipPriceId(membershipPriceId))
        );
      }
    }
  }, [dispatch, jobId, membershipPriceId]);

  return (
    isMembershipPriceId(membershipPriceId) && (
      <WhiteContainer
        className={classNames('Invoice MembershipInvoice', {
          'mt-4': buyNowData.length <= 0 && isFromOrderWizard,
        })}
      >
        {membershipPrices.isLoading ? (
          <Loading />
        ) : (
          selectedMembership &&
          (!isFromOrderWizard ? (
            <MembershipManagementTemplate
              selectedMembership={selectedMembership}
            />
          ) : (
            <OrderWizardTemplate
              selectedMembership={selectedMembership}
              numOrder={numOrder}
            />
          ))
        )}
      </WhiteContainer>
    )
  );
};

MembershipInvoice.propTypes = {
  numOrder: T.number,
  isFromOrderWizard: T.bool,
};
export default MembershipInvoice;

// BREAKDOWN TEMPLATES
const MembershipManagementTemplate = ({ selectedMembership }) => {
  if (isEmpty(selectedMembership)) {
    return null;
  }

  return (
    <>
      <h6 className='green-1'>
        {text('invoiceType', {
          invoiceType: text('membership'),
          invoiceCount: '1.',
        })}
      </h6>
      <List>
        <tbody>
          <List.Item
            description={selectedMembership?.name}
            value={formatCurrency(
              selectedMembership.grand_total,
              selectedMembership.currency.name,
              selectedMembership.currency.scale_factor,
              {},
              true
            )}
          />
        </tbody>
      </List>
      <List>
        <tbody>
          <List.Item
            description={text('subtotal')}
            value={formatCurrency(
              selectedMembership.subtotal,
              selectedMembership.currency.name,
              selectedMembership.currency.scale_factor,
              {},
              true
            )}
          />
          {selectedMembership.taxes.map((tax, i) => (
            <List.Item
              key={i}
              description={tax.display_name}
              value={formatCurrency(
                tax.price.extra,
                selectedMembership.currency.name,
                selectedMembership.currency.scale_factor,
                {},
                true
              )}
            />
          ))}
          <List.Item
            isHighlighted
            description={text('total')}
            value={formatCurrency(
              selectedMembership.grand_total,
              selectedMembership.currency.name,
              selectedMembership.currency.scale_factor,
              {},
              true
            )}
          />
        </tbody>
      </List>
    </>
  );
};
MembershipManagementTemplate.propTypes = {
  selectedMembership: T.object,
};

const OrderWizardTemplate = ({ selectedMembership, numOrder }) => {
  if (isEmpty(selectedMembership)) {
    return null;
  }

  return (
    <>
      <h6 className='green-1 font-weight--600 mb-0'>
        <span className='mr-1'>{numOrder}.</span>{' '}
        <span>
          {text('_invoiceType', {
            prefix: text('estimate'),
            type: text('membership'),
          })}
        </span>
      </h6>
      <div style={{ paddingLeft: '20px', paddingRight: '16px' }}>
        <List>
          <tbody>
            <List.Item
              description={selectedMembership?.name}
              value={formatCurrency(
                selectedMembership?.subtotal,
                selectedMembership?.currency.name,
                selectedMembership?.currency.scale_factor,
                {},
                true
              )}
            />
          </tbody>
        </List>
      </div>
    </>
  );
};
OrderWizardTemplate.propTypes = {
  selectedMembership: T.object,
  numOrder: T.number,
};
