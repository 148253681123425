import React from 'react';
import { useContext, useMemo } from 'react';
import { MapViewContext } from '../../../mapView/mapViewContext';
import text, { formatCurrency, formatSqmArea } from '../../../../text';
import { formatDate, formatMonth } from '../../../../utilities/date';
import {
  PRODUCT_TYPE,
  SALES_HELP_DISCOUNT,
} from '../../../../constants/product';
import { convertToGeoJson } from '../../../../utilities/map';
import { computeArea, computeUnion } from '../../../mapView/geometry';
import { isEmpty } from 'lodash';
import { roundToNearest } from '../../../../utilities/number';
import { DEFAULT_CURRENCY } from '../../../../constants/price';
import { useSelector } from 'react-redux';
import {
  isStaff,
  isUserGovernment,
  isUserUnderTrial,
  userHasValidMembership,
} from '../../../../utilities/user';
import { isMembershipPriceId } from '../../../../utilities/membership';
import { USER_TYPE } from '../../../../constants/user';

const SQM_IN_SQKM = 1e6;
export default function useProduct() {
  const { state } = useContext(MapViewContext);
  const { data, product: productType, isReadOnly } = state.productSelected;
  const product = data[1][0];
  const currency = state.job?.quote?.currency || DEFAULT_CURRENCY;

  const { currentUser, membershipPriceId } = useSelector((reduxState) => ({
    currentUser: reduxState.profileReducer.userProfile,
    membershipPriceId: reduxState.order.membershipPriceId,
  }));

  const isEssentialUser = useMemo(() => {
    if (isUserGovernment(currentUser)) return true;
    return userHasValidMembership(currentUser) || isUserUnderTrial(currentUser);
  }, [currentUser]);

  const selectionsPerProduct = useMemo(() => {
    const products = new Map();
    if (state.selections) {
      state.selections.forEach((selection) => {
        if (products?.[selection.category_name]) {
          products[selection.category_name] = [
            ...products[selection.category_name],
            selection,
          ];
        } else {
          products[selection.category_name] = [selection];
        }
      });
    }
    return products;
  }, [state.selections]);

  const charges = useMemo(() => {
    const products = new Map();
    if (state.job?.quote?.charges) {
      state.job.quote.charges.forEach((charge) => {
        products[charge.display_name] = { ...charge };
      });
    }
    return products[product.display_name];
  }, [state.job, product]);

  const age = useMemo(() => {
    if (!charges) {
      return;
    }

    const acquiredDates = charges.items.reduce((acc, next) => {
      if (!next.details.acquired_at) {
        return []; // to avoid undefined
      }
      return [...acc, formatDate(next.details.acquired_at)];
    }, []);

    if (acquiredDates.length <= 0) {
      return;
    }

    const sortedAcquiredDates = Array.from(new Set(acquiredDates)).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );

    const [startDate, endDate] = [
      sortedAcquiredDates[0],
      sortedAcquiredDates[sortedAcquiredDates.length - 1],
    ];

    if (startDate !== endDate) {
      return text('age', {
        dates: `${formatMonth(startDate)} - ${formatMonth(endDate)}`,
      });
    }

    return text('age', { dates: formatMonth(startDate) });
  }, [charges]);

  const totalArea = useMemo(() => {
    if (!charges) {
      return {
        formatted: '',
        value: 0,
      };
    }

    if (charges?.details?.area_in_sqm) {
      if (charges.details.area_in_sqm > SQM_IN_SQKM) {
        return {
          formatted: `${(
            charges.details.area_in_sqm / SQM_IN_SQKM
          ).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })} km²`,
          value: charges.details.area_in_sqm / SQM_IN_SQKM,
        };
      }

      return {
        formatted: formatSqmArea(charges.details.area_in_sqm),
        value: charges.details.area_in_sqm,
      };
    }

    return {
      formatted: '',
      value: 0,
    };
  }, [charges]);

  // Only calculate for Interior shapes union since
  // it's the only shape that computes area linearly
  // other shapes area is equal to union by default
  const shapesUnionArea = useMemo(() => {
    if (
      ![
        PRODUCT_TYPE.STREETSCAPE,
        PRODUCT_TYPE.INTERIOR,
        PRODUCT_TYPE.BIM_INTERIOR,
        PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
      ].includes(productType)
    ) {
      return {
        formatted: '',
        value: 0,
      };
    }

    const shapeCoordinates = convertToGeoJson(
      state.selections?.filter(
        (selection) => selection.display_name === product.display_name
      )
    );

    const unionCoordinates = [...shapeCoordinates.features].reduce(
      (prev, next) => {
        if (!isEmpty(prev)) {
          return computeUnion(prev, next.geometry);
        }
        return next.geometry;
      },
      {}
    );

    let unionArea = !isEmpty(unionCoordinates) && computeArea(unionCoordinates);

    if (unionArea) {
      const diff = Math.abs(Math.floor(unionArea) - totalArea.value);
      unionArea = roundToNearest(unionArea, 1);

      if (diff <= 1) {
        // deny shape union if difference between area & union is <= 1,
        // this happens due to rouding off of area
        unionArea = 0;
      }

      if (unionArea > SQM_IN_SQKM) {
        return {
          formatted: `${(unionArea / SQM_IN_SQKM).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })} km²`,
          value: unionArea / SQM_IN_SQKM,
        };
      }

      return {
        formatted: unionArea > 0 ? formatSqmArea(unionArea) : '',
        value: unionArea,
      };
    }
  }, [totalArea]);

  const shapes = useMemo(() => {
    return selectionsPerProduct[productType];
  }, [selectionsPerProduct, productType]);

  const price = useMemo(() => {
    if (!charges) {
      return {
        formatted: '',
        value: 0,
      };
    }

    if (!charges || !charges.price) {
      return {
        formatted: text('notAvailable'),
        value: null,
      };
    }
    if (!charges.price.total) {
      return {
        formatted: text('toBeConfirmed'),
        value: null,
      };
    }

    const price = isEssentialUser
      ? charges.price.member_total
      : charges.price.total;

    return {
      formatted: formatCurrency(
        price,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
      value: price,
    };
  }, [charges?.price, currency]);

  const isFreeOrTrial = useMemo(() => {
    if (!charges) return;

    return (
      !isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role)
    );
  }, [charges?.price]);

  const hasMemberDiscount = useMemo(() => {
    if (!charges) return;

    return charges.price?.member_discount_total > 0;
  }, [charges?.price]);

  const memberDiscount = useMemo(() => {
    const totalAmount = charges ? charges.price.member_total : 0;
    const savingAmount = charges ? charges.price.member_discount_total : 0;

    const saving = formatCurrency(
      savingAmount,
      currency.name,
      currency.scale_factor,
      {},
      true
    );

    if (!isEssentialUser || isStaff(currentUser.role)) {
      return text('memberPriceDiscount', {
        totalAmount: formatCurrency(
          totalAmount,
          currency.name,
          currency.scale_factor,
          {},
          true
        ),
        saving,
      });
    }

    return (
      <>
        <span className='text-strikethrough'>
          {text('fullPrice', {
            totalAmount: formatCurrency(
              charges?.price?.subtotal,
              currency.name,
              currency.scale_factor,
              {},
              true
            ),
          })}
        </span>
        {', '}
        {text('savingAmount', { saving })}
      </>
    );
  }, [isEssentialUser, charges?.price]);

  const priceWithSalesHelp = useMemo(() => {
    if (!charges) {
      return {
        formatted: '',
        value: 0,
      };
    }
    const userType = isEssentialUser ? USER_TYPE.ESSENTIALS : USER_TYPE.FREE;
    const salesHelpDiscount = SALES_HELP_DISCOUNT[productType][userType];

    if (salesHelpDiscount <= 0) {
      return text('notAvailable');
    }

    if (!charges || !charges.price) {
      return text('notAvailable');
    }
    if (!charges.price.total) {
      return text('toBeConfirmed');
    }
    const salesHelpSaving = charges.price.subtotal * (salesHelpDiscount / 100);
    return {
      total: formatCurrency(
        price.value + salesHelpSaving,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
      saved: formatCurrency(
        salesHelpSaving,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
      value: salesHelpSaving,
    };
  }, [charges?.price, currency, productType]);

  return {
    selectionsPerProduct,
    charges,
    age,
    totalArea,
    shapesUnionArea,
    shapes,
    price,
    isEssentialUser,
    isFreeOrTrial,
    hasMemberDiscount,
    memberDiscount,
    priceWithSalesHelp,
    productType,
    isReadOnly,
    isStaff: isStaff(currentUser.role),
  };
}
