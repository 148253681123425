/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { forwardRef } from 'react';

import ShapeMenuContent from './ShapeMenuContent';

// We're using a Component-as-prop pattern here so that the <Dropdown.Menu /> component can be customized but with the ref forwarded and shape-menu class added for styles.
export default forwardRef(function ShapeMenu(
  {
    data,
    DropdownMenuComponent,
    className,
    showShapeName,
    areaType,
    popupTrigger,
    closeMenu,
    ...props
  },
  ref
) {
  return (
    <DropdownMenuComponent
      ref={ref}
      className={classNames(className, 'MeatballDropdown shape-menu')}
      {...props}
    >
      <ShapeMenuContent
        showShapeName={showShapeName}
        areaType={areaType}
        data={data}
        popupTrigger={popupTrigger}
        closeMenu={closeMenu}
      />
    </DropdownMenuComponent>
  );
});
