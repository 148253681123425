/* eslint-disable react/jsx-max-depth */
import classnames from 'classnames';
import { capitalize, isEmpty } from 'lodash';
import T from 'prop-types';
import React from 'react';
import { Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import remarkGfm from 'remark-gfm';
import MeatballDropdown from '../../components/dropdown/MeatballDropdown';
import Icon from '../../components/icon/Icon';
import { CONFIRM_MODAL } from '../../components/modal/ConfirmModal';
import Activity from '../../components/timeline/Activity';
import { deleteActivity, deleteAsset, showModal } from '../../redux/actions';
import text from '../../text';
import {
  isActivityFileDownload,
  isActivityType,
} from '../../utilities/activity';
import { formatDate, formatTime } from '../../utilities/date';
import { isAdministrator, isStaff } from '../../utilities/user';
import ActivityAsset from './ActivityAsset';
import JobStatusUpdate from './JobStatusUpdate';

const TypeIconMap = {
  asset: 'clip',
  order: 'shopping-cart',
  note: 'speech',
  share: 'user-outline',
  larki: 'larki-icon',
  status: '?',
};
// TODO: Refactor this to project activity
const JobActivity = (props) => {
  const {
    jobActivity: {
      id,
      created_at,
      created_assets,
      title,
      description,
      type,
      job_id,
      project_id,
      detailed_status_update,
      is_notification_sent,
      visibility,
      created_user,
    },
    currentProject,
    currentUser,
  } = props;
  const dispatch = useDispatch();

  const onDeleteActivity = () => {
    dispatch(
      showModal(CONFIRM_MODAL, {
        onConfirm: () => dispatch(deleteActivity(project_id, id)),
        title:
          type === 'note'
            ? text('deleteNote')
            : text('deleteFileUploadActivity'),
        body:
          type === 'note'
            ? text('deleteNoteBody')
            : text('deleteFileUploadActivityBody'),
        okLabel: text('delete'),
      })
    );
  };

  const onDeleteAsset = (assetId) => {
    dispatch(
      showModal(CONFIRM_MODAL, {
        onConfirm: () => dispatch(deleteAsset(project_id, job_id, id, assetId)), // id = activityId
        title: text('deleteFile'),
        body: text('deleteFileBody'),
        okLabel: text('delete'),
      })
    );
  };

  const getActivityIcon = () => {
    // handle old File Downloaded activities w/c are still under assets
    if (isActivityFileDownload({ title, description }) && type !== 'larki') {
      return TypeIconMap.larki;
    }
    return TypeIconMap[type] || 'speech';
  };

  const getActivityType = (type) => {
    // handle old File Downloaded activites that were saved as notes
    if (
      ['note', 'larki'].includes(type) &&
      isActivityFileDownload({ title, description })
    ) {
      return 'download';
    }
    return type;
  };

  const { jobActivity } = props;
  return !isActivityType(jobActivity, 'status') ? (
    <Activity
      className={classnames('JobActivity', { isStaff: isStaff(visibility) })}
    >
      <>
        <Activity.Icon iconProps={{ icon: getActivityIcon() }} />
        <Activity.Card>
          <Activity.Card.Header>
            <Row className='m-0 w-100'>
              <div className='main-content'>
                <Activity.Card.Type
                  type={type ? capitalize(getActivityType(type)) : null}
                />

                <Activity.Card.Date
                  isLastItem={!job_id}
                  date={formatDate(created_at)}
                  time={formatTime(created_at)}
                />

                {job_id ? (
                  <Activity.Card.HeaderElement className='jobId'>
                    ID:{job_id}
                  </Activity.Card.HeaderElement>
                ) : null}

                {is_notification_sent ? (
                  <OverlayTrigger
                    delay={{ show: 250 }}
                    placement='auto'
                    overlay={
                      <Tooltip className='notification-sent'>
                        {text('notificationSent')}
                      </Tooltip>
                    }
                  >
                    <Icon icon='envelope' />
                  </OverlayTrigger>
                ) : null}
              </div>
              {isStaff(visibility) && (
                <span className='larki-tag'>{`[${text('larkiOnly')}]`} </span>
              )}
              <Activity.Card.HeaderRight>
                {(!created_user?.full_name || isStaff(created_user?.role)) &&
                !isStaff(currentUser?.role)
                  ? 'LARKI'
                  : created_user?.full_name}
                {['asset', 'note'].includes(type) &&
                isAdministrator(currentUser?.role) ? (
                  <MeatballDropdown
                    btnClassName='icon-button ActivityMeatballMenuButton'
                    tooltipProps={{
                      text: text('more'),
                    }}
                  >
                    <Dropdown.Item onClick={onDeleteActivity}>
                      {text('delete')}
                    </Dropdown.Item>
                  </MeatballDropdown>
                ) : null}
              </Activity.Card.HeaderRight>
            </Row>
            {job_id && !isEmpty(currentProject?.jobs) ? (
              <Row className='w-100 d-flex'>
                {
                  currentProject.jobs.find((job) => job.id === job_id)
                    ?.full_address
                }
              </Row>
            ) : null}
          </Activity.Card.Header>
          <div className='ActivityContent'>
            {isEmpty(created_assets) && (
              <div className='ActivityTitle'>{title}</div>
            )}
            {created_assets ? (
              <ActivityAsset
                onDelete={onDeleteAsset}
                projectId={project_id}
                assets={created_assets}
                currentUser={currentUser}
              />
            ) : null}
            <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
              {description}
            </ReactMarkdown>
          </div>
        </Activity.Card>
      </>
    </Activity>
  ) : (
    <JobStatusUpdate
      detailedStatuses={detailed_status_update}
      createdDate={created_at}
      activityTitle={title}
      activityDescription={description}
    />
  );
};

JobActivity.propTypes = {
  jobActivity: T.shape(),
  currentUser: T.shape(),
  currentProject: T.shape(),
};

export default JobActivity;
